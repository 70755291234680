@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@font-face {
	font-family: 'CenturyGothicBold';
	src: url('../fonts/CenturyGothicBold.eot');
	src: url('../fonts/CenturyGothicBold.eot') format('embedded-opentype'), url('../fonts/CenturyGothicBold.woff2') format('woff2'), url('../fonts/CenturyGothicBold.woff') format('woff'), url('../fonts/CenturyGothicBold.ttf') format('truetype'), url('../fonts/CenturyGothicBold.svg#CenturyGothicBold') format('svg');
}

@font-face {
	font-family: 'CenturyGothicRegular';
	src: url('../fonts/CenturyGothicRegular.eot');
	src: url('../fonts/CenturyGothicRegular.eot') format('embedded-opentype'), url('../fonts/CenturyGothicRegular.woff2') format('woff2'), url('../fonts/CenturyGothicRegular.woff') format('woff'), url('../fonts/CenturyGothicRegular.ttf') format('truetype'), url('../fonts/CenturyGothicRegular.svg#CenturyGothicRegular') format('svg');
}
@font-face {
    font-family: 'MyriadProRegular';
    src: url('../fonts/MyriadProRegular.eot');
    src: url('../fonts/MyriadProRegular.eot') format('embedded-opentype'),
         url('../fonts/MyriadProRegular.woff2') format('woff2'),
         url('../fonts/MyriadProRegular.woff') format('woff'),
         url('../fonts/MyriadProRegular.ttf') format('truetype'),
         url('../fonts/MyriadProRegular.svg#MyriadProRegular') format('svg');
}
@font-face {
    font-family: 'MyriadProBold';
    src: url('../fonts/MyriadProBold.eot');
    src: url('../fonts/MyriadProBold.eot') format('embedded-opentype'),
         url('../fonts/MyriadProBold.woff2') format('woff2'),
         url('../fonts/MyriadProBold.woff') format('woff'),
         url('../fonts/MyriadProBold.ttf') format('truetype'),
         url('../fonts/MyriadProBold.svg#MyriadProBold') format('svg');
}

body {
	font-family: 'CenturyGothicRegular' !important;
	font-size: 17px;
	line-height: 28px;
	color: #1D1D1B;
	font-weight: 400;
	width: 100%;
	overflow-x: hidden;
	color: #111;
    background: #ededed !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	font-weight: 500;
	font-family: 'CenturyGothicBold';
	color: #111;
}

h1 {
	font-size: 46px;
	line-height: 70px;
	color: #000000;
	font-weight: 500;
}

h2 {
	font-size: 41px;
	line-height: 27px;
	color: #000000;
	font-weight: 500;
}

h3 {
	font-size: 34px;
	line-height: 48px;
	color: #000000;
	font-weight: 500;
}

h5 {
	font-size: 22px;
	line-height: 27px;
	color: #000000;
	font-weight: 700;
}

h6 {
	font-size: 18px;
	line-height: 28px;
	color: #1D1D1B;
	font-weight: 400;
}

body p {
	font-family: 'CenturyGothicRegular';
	font-size: 17px;
	line-height: 28px;
	color: #1D1D1B;
	font-weight: 400;
}

a,
*::before,
*::after,
img,
span,
input,
button,
.navbar,
.fixedmenu,
.tp-bullet,
.owl-dot,
.owl-prev,
.owl-next {
	transition: ease-in-out 0.2s;
	-webkit-transition: ease-in-out 0.2s;
	-moz-transition: ease-in-out 0.2s;
	-ms-transition: ease-in-out 0.2s;
	-o-transition: ease-in-out 0.2s;
}

section,
.section-padding {
	width: 100%;
	float: left;
	position: relative;
	padding: 70px 0;
}

body a {
	color: #099da6;
}

body a:hover,
a:hover {
	text-decoration: none;
}

body ul {
	list-style: disc;
	width: 100%;
	padding: 0;
	margin: 0 0 0 30px;
}

body ul li {
	font-family: 'CenturyGothicRegular';
	font-size: 17px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
	color: #111;
	position: relative;
	padding: 0 20px 0 5px;
	margin: 0 0 5px 0;
	width: 100%;
}

body ul li span {
	font-weight: 500;
}

.left {
	float: left;
}

.right {
	float: right;
}

.p0 {
	padding: 0px;
}

.pl0 {
	padding-left: 0px;
}

.pr0 {
	padding-right: 0px;
}
.sec-center {
	margin: 0 auto;
	text-align: center;
}
.center{
	text-align:center;
}
.black_btn {
	height: 50px;
	width: auto;
	color: #FFFFFF !important;
	font-family: 'CenturyGothicBold';
	font-size: 17px !important;
	background: #1D1D1B !important;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	border: 1px solid #1D1D1B !important;
	border-radius: 0px !important;
	    padding: 12px 35px;
}

.black_btn::before {
	content: '';
	position: absolute;
	left: 5px;
	bottom: -5px;
	width: 100%;
	height: 100%;
	border: 1px solid #1D1D1B;
	z-index: -1;
}
/*
.black_btn:hover {
	background-color: #007bff !important;
	border: 1px solid #007bff !important;
}
.black_btn:hover::before {
	border: 1px solid #007bff;
}*/
/*header*/
.logo-section {
	margin: 30px 0 0 0;
}

.logo-section img {
	width: 125px;
}


/*register form-section*/

.home-register-section {
	padding: 20px 0 15px 0;
}

.home-register-form-div {
	width: 700px;
	margin: 0 auto;
	    padding: 0px 30px;
    display: table;
}

.form-logo-section {
	margin: 0 0 45px 0;
}

.form-logo-section img {
	width: 98px;
	height: 98px;
}

.home-register-form-div h3 {
	color: #1D1D1B;
	font-family: 'CenturyGothicBold';
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 0;
	text-align: center;
	margin: 0 0 30px 0;
	text-transform: uppercase;
}

.home-register-form-div input[type="text"],
.home-register-form-div input[type="email"],
.home-register-form-div input[type="tel"], .home-register-form-div input[type="tel"], 
.home-register-form-div input[type="password"] {
	color: #1D1D1B;
	font-family: 'CenturyGothicBold';
	font-size: 16.65px;
	font-weight: bold;
	letter-spacing: 0;
	box-sizing: border-box;
	height: 47.36px;
	width: 100%;
	border: 1px solid #1D1D1B;
	border-radius: 0px;
}
.home-register-form-div .form-group {
    margin-bottom: 50px;
}
.form-group.checkbox-div {
	margin: 12px 0 35px 0;
	display: inline-block;
}
.home-register-form-div input[type="text"]::placeholder::nth-last-letter(1) /* Only for Chrome */
{
   color:red;
}
.home-register-form-div .form-group .email-error-msg {
    color: #f00;
	font-weight: 600;
}
.email-success-msg {
    color: #46b450;
    font-weight: 600;
}
/* checkbox aspect */
.home-register-form-div input[type="checkbox"] {
	width: 25px !important;
	float: left;
	margin: 0px;
	-webkit-appearance: none;
	height: 25px;
	box-sizing: border-box;
	border: 1px solid #1D1D1B;
}

.home-register-form-div input[type="checkbox"]:focus {
	outline: none;
}

.home-register-form-div .checkbox-div label {
	width: calc(100% - 30px) !important;
	width: -webkit-calc(100% - 30px) !important;
	padding-left: 15px;
	position: relative;
	line-height: 22px;
	color: #1D1D1B;
	font-family: 'CenturyGothicBold';
	font-size: 17px;
	margin-top: -3px;
}


input[type='checkbox']:checked{
	position: relative;
}

input[type='checkbox']:checked:before {
	content: '\f00c';
	display: block;
	width: 34px;
	height: 30px;
	position: absolute;
	top: -3px;
	left: 2px;
	background: none;
	line-height: 29px;
	font-family: "FontAwesome";
	color: #1D1D1B;
	font-size: 21px;
}

.register-btn {
	height: 60px;
	width: 220px;
	color: #FFFFFF !important;
	font-family: 'CenturyGothicBold';
	font-size: 17px !important;
	background: #1D1D1B !important;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	border: 1px solid #1D1D1B !important;
	border-radius: 0px;
	opacity: 1 !important;
	border-radius: 0px !important;
}

.register-btn::before {
	content: '';
	position: absolute;
	left: 5px;
	bottom: -5px;
	width: 100%;
	height: 100%;
	border: 1px solid #1D1D1B;
	z-index: -1;
}
.black_btn:focus, .register-btn:focus {
    outline: none;
}
/*
.register-btn:hover {
	background-color: #007bff !important;
	border: 1px solid #007bff !important;
}

.register-btn:hover::before {
	border: 1px solid #007bff;
}
*/
.form-bottom-text {
	    width: 100%;
    float: left;
	margin: 80px 0 0 0;
}

.form-bottom-text p {
	color: #1D1D1B;
	font-family: 'CenturyGothicBold';
	font-size: 15px;
	text-align: center;
}
.home-register-form .col-md-12 {
    width: 100%;
    float: left;
}
/*form-section end*/
/*T-shirt selection page*/
.radio-head p {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 17px;
}
.radio-head p span{
	    color: #E30613;
}

.radio-section.radio-1-section .radio-left {
    width: 50%;
    float: left;
    display: block;
}
.radio-section.radio-1-section .radio-right {
    width: 50%;
    float: left;
}

.radio-section .radio-custom {
    width:auto;
    float: left;
}
.radio-section .radio-custom-label {
    width: 100%;
    float: left;
    padding: 27px 0 0 0;
    text-align: center;
    color: #1D1D1B;
    font-family: 'CenturyGothicRegular';
    font-size: 19px;
	    margin: 0px;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
}
/*
radio button css*/
.checkbox-custom, .radio-custom {
    opacity: 0;
    position: absolute;   
}

.radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.radio-custom-label {
    position: relative;
}

.radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #1D1D1B;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 3px;
    margin-right: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #1D1D1B;
    font-size: 17px;
    font-weight: 300;
}

.radio-section .radio-custom-label:focus {
    outline: 0;
}
/*radio button css END*/
.radio-section.radio-1-section .radio-section-inner {
    width: 300px;
}
/*2nd row*/
.radio-section-inner {
    display: flex;
    justify-content: space-around;
	    margin: 30px auto;
}
.radio-section.radio-2-section .radio-section-inner {
    width: 500px;
}
/*3rd row*/
.radio-section.radio-3-section .radio-section-inner {
    width: 100%;
    justify-content: space-between;
	display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
	    display: flex;
}
.color_box {
    position: relative;
    width: 110px;
    height: 40px;
    display: inline-block;
    margin: 20px auto;
    text-align: center;
}
.color_box.blue_box {
    border: 1px solid #1D1D1B;
    background: #27348B;
}
.color_box.gray_box {
    border: 1px solid #1D1D1B;
    background: #706F6F;
}
.color_box.black_box {
    border: 1px solid #1D1D1B;
    background: #1D1D1B;
}
.color_box.white_box {
    border: 1px solid #1D1D1B;
    background: #fff;
}
.radio-section.radio-3-section .radio-left, .radio-section.radio-3-section .radio-center, .radio-section.radio-3-section .radio-right, .radio-section.radio-3-section .radio-right-last {
    text-align: center;
}
/*nextbtn*/
.next-btn-section {
    margin: 30px 0 30px 0px;
}
.next-btn-section .register-btn.next-btn {
    width: 150px;
    height: 50px;
}
/*T-shirt selection page END*/


/*design page*/
.design-menu-inner {
    margin: 23px 0 0 0;
}
.design-menu-inner ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    float: left;
}
.design-menu-inner ul li {
    text-align: center;
    width: 100%;
    margin: 0 0 30px 0;
    display: flex;
    justify-content: center;
	padding: 0px;
}
.design-menu-inner ul li a, .design-menu-inner ul li button {
    width: 360px;
    height: 90px;
    border: 2px solid #878787;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 23px;
    cursor: pointer !important;
    z-index: 9;
	text-transform: uppercase;
	background: #fff;
}
.design-menu-inner ul li button:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.footer-btn {
    width: 360px;
    margin: 50px auto 40px auto;
    display: table;
}
.footer-btn .footer-left-btn {
    width: 50%;
    float: left;
    text-align: left;
    padding: 0 0 0 15px;
}
.footer-btn .footer-right-btn {
    width: 50%;
    float: left;
    text-align: right;
    padding: 0 15px 0 0;
}
.footer-btn a.black_btn, .footer-btn button.black_btn {
    padding: 12px 45px;
}
/*design page END*/

/*logo page*/
.design-logo-grid-outer {
    width: 100%;
    float: left;
    min-height: 550px;
}
.home-register-form-div.design-menu-sec.design-logo-sec h4 {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 24px;
    text-transform: uppercase;
	    padding: 0 0 0 15px;
		    margin: 0 0 33px 0;
}
.home-register-form-div.design-menu-sec.design-logo-sec h3 {
    margin: 0 0 0px 0;
}
.design-logo-grid-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-row-gap: 27px;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-img {
    width: 100%;
    float: left;
    border: 2px solid #878787;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
	    cursor: pointer;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-img.active {
    border: 5px solid #009fe3;
}
.design-menu-sec.design-logo-sec.design-icon-sec .design-logo-grid-inner .logo-grid-box-img.active{
    border: 5px solid #009fe3;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-content h5 {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 12px;
    text-align: left;
    margin: 0px;
	text-transform: capitalize;
}
/*logo page END*/

/*graphics page*/
.design-logo-grid-outer.design-graphics-grid-outer .design-logo-grid-inner .logo-grid-box .logo-grid-box-img {
    display: flex;
    justify-content: center;
    align-items: center;
	    flex-wrap: wrap;
}
.design-logo-grid-outer.design-graphics-grid-outer .design-logo-grid-inner .logo-grid-box .logo-grid-box-img img {
    width: 72px !important;
    height: 74px !important;
    object-fit: contain;
}
/*graphics page END*/
/*alphabets page*/
.design-logo-grid-outer.design-alphabets-grid-outer .design-logo-grid-inner {
    grid-template-columns: 1fr 1fr 1fr;
}
.design-logo-grid-outer.design-alphabets-grid-outer .logo-grid-box-img {
    border: 2px solid #878787;
    height: 137px !important;
    width: 164px;
}
.home-register-form-div.design-menu-sec.design-logo-sec.design-alphabet-sec {
    padding: 0 80px;
}
/*alphabets page END*/
/*icon page*/
.design-menu-sec.design-logo-sec.design-icon-sec .design-logo-grid-inner {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	    grid-row-gap: 20px;
}
.design-menu-sec.design-logo-sec.design-icon-sec .design-logo-grid-inner .logo-grid-box-img {
    height: auto;
    border: none;
	padding:0px;
}
.design-menu-sec.design-logo-sec.design-icon-sec .design-logo-grid-inner .logo-grid-box-img img {
    width: 100% !important;
    height: 35px !important;
    object-fit: contain;
}
.design-menu-sec.design-logo-sec.design-icon-sec h4 {
    padding: 0 0 0 30px;
}

.design-logo-grid-inner.mt20 {
    margin: 20px 0 0 0;
}
.design-menu-sec.design-logo-sec.design-icon-sec .design-logo-grid-inner-10-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.design-icon-sec .design-logo-grid-outer {
    min-height: 430px;
}
/*icon page END*/

/*layout page*/
.head-black-box {
    width: 150px;
    height: 150px;
    background: #1D1D1B;
    float: right;
	margin: -10px 0 0 0;
}
.design-layout-grid-outer {
    display: grid;
    grid-template-columns: auto 100px;
	min-height: 396px;
	grid-gap: 10px;
}
.design-layout-grid-box-inner-left {
    width: 100%;
	    height: 100%;
    float: left;
    border: 2px dashed #979797;
    background-image: url(../images/graph.png);
    background-size: cover;
    background-position: center;
}
.design-layout-grid-box-inner-left img {
    width: 323px;
    height: 218px;
    object-fit: cover;
}
.design-layout-grid-box.design-layout-grid-box-right {
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.design-layout-grid-box-inner-right img {
    width: 100px;
    height: 98px;
    object-fit: contain;
}

.footer-btn-large {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	padding: 15px 20px 0 0;
}
.footer-btn-large .footer-left-btn, .footer-btn-large .footer-center-btn, .footer-btn-large .footer-right-btn {
    width: 100%;
}
.design-layout-grid-box-inner-right {
    position: relative;
}
.grid-side-image-square {
    position: absolute;
    bottom: 22px;
    right: 50px;
}
.grid-side-image-square .black_border_box {
    width: 30px;
    height: 43px;
    border: 1px dashed #ccc;
}	
/*layout page END*/

/*t-shirt front page*/
.design-mock-outer-image-box-shirt {
    position: relative;
    width: 100%;
    float: left;
}
.design-mock-outer-image-box-logo {
    position: absolute;
    top: 85px;
    left: 50.4%;
    transform: translateX(-50%);
    height: 50%;
    width: 28%;
}
.design-mock-outer-image-box-logo.back{ 
    top: 65px;
}
.design-mock-outer-image-box-logo img {
    width: 100%;
    object-fit: contain;
}
.design-mock-outer {
    width: 100%;
    float: left;
    margin: 0 0 25px 0;
}
/*t-shirt front page END*/
/*loginpage*/
.home-register-section.login-section .register-btn {
    margin: 20px 0 70px 0;
}
/*loginpage END*/

/*queuing table*/


.queing-table-section {
    width: 1140px;
    padding: 0px;
}
.queing-table-menu ul {
    width: 100%;
    list-style: none;
    margin: 0px;
    padding: 10px 0 52px 0;
    display: table;
}
.queing-table-menu ul li {
    display: table;
    padding: 0px;
    margin: 0px;
    flex-wrap: wrap;
    width: auto;
    float: left;
}
.queing-table-menu ul li:nth-child(2) {
    margin-left: -2px;
}
.queing-table-menu ul li button {
    color: #1D1D1B;
    font-family: 'CenturyGothicRegular';
    font-size: 18px;
    border: 1px solid #1D1D1B;
    background: #fff;
    height: 44px;
    padding: 0px 15px;
    text-transform: uppercase;
}
.queing-table-menu ul li.listjobs button:focus{
    outline: none !important;
}
.queing-table-menu ul li.listjobs button {
    border: 2px solid #27348B;
}
.queing-table-inner table thead th {
    color: #1D1D1B;
    font-family: 'CenturyGothicRegular';
    font-size: 24px;
	  border-bottom: 1px solid #1D1D1B;
	    font-weight: 400;
}
.queing-table-inner table tbody tr td {
    color: #1D1D1B;
    font-family: 'CenturyGothicRegular';
    font-size: 20px;
}
.queing-table-inner .table-bordered td, .queing-table-inner .table-bordered th {
    border: 1px solid #1D1D1B;
}
button.btn_complete {
    color: #FEFEFE;
    font-family: 'CenturyGothicRegular';
    font-size: 13px;
    text-transform: uppercase;
    background: #009EE2;
    border: 1px solid #009EE2;
    padding: 2px 45px;
    border-radius: 30px;
	    margin: 0 auto;
    display: flex;
}
button.btn_delete {
    color: #FEFEFE;
    font-family: 'CenturyGothicRegular';
    font-size: 13px;
    text-transform: uppercase;
    background: #E20714;
    border: 1px solid #E20714;
    padding: 2px 45px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
}
.quee-table-pagination {
    float: right;
    display: table;
}
.quee-table-pagination ul.pagination {
    margin: 35px 0px 50px 0;
	    padding: 0 80px 0 0;
}
.quee-table-pagination ul li {
    padding: 0px;
    margin: 0px;
}
.queing-table-inner .table-bordered td:nth-child(2) {
    text-transform: capitalize;
}
/*queuing table END*/

/*complate job page*/

button.btn_complete_green {
    color: #FEFEFE;
    font-family: 'CenturyGothicRegular';
    font-size: 13px;
    text-transform: uppercase;
    background: #006633;
    border: 1px solid #006633;
    padding: 2px 12px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
}
button.small_btn_delete{
	    padding: 2px 25px;
}
button.btn_sms {
    color: #FEFEFE;
    font-family: 'CenturyGothicRegular';
    font-size: 13px;
    text-transform: uppercase;
    background: #ec9726;
    border: 1px solid #ec9726;
    padding: 2px 30px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
}
button.btn_sms.active{
    background: #009fe3;
    border: 1px solid #009fe3;
}
th.complete-green-status {
    width: 230px;
}
/*complate job END*/

/*details page*/
.dashboard.section {
    padding: 0px 0 15px 0;
	    margin-top: -50px;
}
.dashboard-div {
    width: 98%;
    padding: 0px;
	 display: table;
     margin: 0 auto;
	
}
.header-section.dashboard-header .logo-section {
    margin: 20px 0 0 0;
}

.detail-inner-full-section {
    display: grid;
    grid-template-columns: 40% 55%;
	grid-gap: 5%;
}
.detail-inner-full-section .detail-inner-full-section-left h3 {
    text-align: left;
	margin: 0 0 36px 0;
}
.detail-inner-full-section-left .col-md-12 {
    padding: 0px;
}
form.detail-form .form-group input {
    font-size: 17px;
    text-transform: uppercase;
	    margin-bottom: 25px;
}
form.detail-form .form-group.place-bold-div input {
    font-family: 'CenturyGothicRegular';
    font-size: 20px;
    font-weight: 400;
	    text-transform: capitalize;
}
form.detail-form .form-group.place-bold-div label {
    font-family: 'CenturyGothicBold';
    font-size: 17px;
}
.detail-inner-full-section-right .design-mock-outer {
    width: auto;
    position: relative;
}
/*right side*/
.detail-inner-full-section-right h3 {
    text-align: left;
    margin: 0 0 36px;
}
.detail-inner-full-section-right {
    width: 100%;
    float: left;
    margin: 20px 0 0 0;
}
.detail-inner-full-section-right .design-mock-outer {
    width: 100%;
    border: 1px solid #1D1D1B;
    display: flex;
    justify-content: center;
    padding: 35px 10px;
}
.detail-inner-full-section-right .design-mock-outer .design-mock-outer-image-box-shirt img {
    width: 595px;
}
.detail-inner-full-section-right .design-mock-outer .design-mock-outer-image-box-logo {
    top: 156px;
}
.bottom-arrow-div {
    text-align: right;
}
.bottom-arrow-div.back {
    text-align: left;
    padding: 9px 0 0;
    width: auto;
    float: left;
}
.bottomcomplete-button {
    width: auto;
    text-align: center;
    float: left;
    padding-left: 30px;
}
button.bottom-arrow-btn {
    height: 43px;
    width: 55px;
    color: #FFFFFF !important;
    font-family: 'CenturyGothicBold';
    font-size: 22px !important;
    background: #1D1D1B !important;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    border: 1px solid #1D1D1B !important;
    border-radius: 0px;
    opacity: 1 !important;
	margin-right: 5px;
}
button.bottom-arrow-btn:before{
	    content: '';
    position: absolute;
    left: 5px;
    bottom: -5px;
    width: 100%;
    height: 100%;
    border: 1px solid #1D1D1B;
    z-index: -1;
}
button.bottom-arrow-btn:hover{
	    background-color: #007bff !important;
    border: 1px solid #007bff !important;
}
button.bottom-arrow-btn:hover::before {
    border: 1px solid #007bff;
}
button.bottom-arrow-btn:focus {
    outline: none;
}
/*details page END*/

/*backend gridview*/
.gridview-outer-section {
    margin: 25px 0 0 0;
}
.grid-view-full-section {
    width: 100%;
    display: grid;
    grid-template-columns: 400px auto;
}
.grid-view-full-section-left h3 {
    text-align: left;
    margin: 0px;
}

.dashboard-top-section {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.dashboard-top-section-right .search-form-div input[type="search"] {
    width: 230px;
    height: 45px;
    padding: 10px;
    border: 2px solid #1D1D1B;
    border-radius: 0px;
}
.grid-view-section-right {
    text-align: right;
}
.grid-view-section-right h3 {
    margin: 0 0 23px 0;
    text-align: left;
}
.medialibrary-grid-section {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 0 3px;
    max-height: 412px;
    overflow: scroll;
}
.medialibrary-box {
    background: #ccc;
    margin: 0 7px 7px 0;
}
.medialibrary-box.active{
    border: 5px solid #009fe3;
}
.medialibrary-box img {
    width: 97px;
    height: 97px;
    object-fit: contain;
}
.add-btn-div button.additem_btn {
    background: #1d1d1b;
    color: #fff;
    font-weight: 600;
    padding: 6px 25px;
    height: 45px;
	border-radius: 0px !important;
    float: left;
}
.add-btn-div button.additem_btn:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.add-media-title {
    text-align: left;
}
/*backend gridview END*/

/*add-media page*/

.add-media-file-div .button {
	background      : #005f95;
	border          : none;
	border-radius   : 3px;
	color           : white;
	display         : inline-block;
	font-size       : 19px;
	font-weight     : bolder;
	letter-spacing  : 0.02em;
	padding         : 10px 20px;
	text-align      : center;
	text-shadow     : 0px 1px 2px rgba(0, 0, 0, 0.75);
	text-decoration : none;
	text-transform  : uppercase;
	transition      : all 0.2s;
}

.add-media-file-div .btn:hover {
	background : #4499c9;
}

.add-media-file-div .btn:active {
	background : #49ADE5;
}

.add-media-file-div input[type="file"] {
	display : none;
}

.add-media-file-div #file-drag {
	border        : 2px dashed #555;
	border-radius : 7px;
	color         : #555;
	cursor        : pointer;
	display       : block;
	font-weight   : bold;
	margin        : 1em 0;
	padding       : 3em;
	text-align    : center;
	transition    : background 0.3s, color 0.3s;
}

.add-media-file-div #file-drag:hover {
	background : #ddd;
}

.add-media-file-div #file-drag:hover,
.add-media-file-div #file-drag.hover {
	border-color : #3070A5;
	border-style : solid;
	box-shadow   : inset 0 3px 4px #888;
	color        : #3070A5;
}

.add-media-file-div #file-progress {
	display : none;
	margin  : 1em auto;
	width   : 100%;
}

.add-media-file-div #file-upload-btn {
	margin : auto;
}

.add-media-file-div #file-upload-btn:hover {
	background : #4499c9;
}

.add-media-file-div #file-upload-form {
	margin : auto;	
	width  : 40%;
}

.add-media-file-div progress {
	appearance    : none;
	background    : #eee;
	border        : none;
	border-radius : 3px;
	box-shadow    : 0 2px 5px rgba(0, 0, 0, 0.25) inset;
	height        : 30px;
}

.add-media-file-div progress[value]::-webkit-progress-value {
	background :
		-webkit-linear-gradient(-45deg, 
			transparent 33%,
			rgba(0, 0, 0, .2) 33%, 
			rgba(0,0, 0, .2) 66%,
			transparent 66%),
		-webkit-linear-gradient(right,
			#005f95,
			#07294d);
	background :
		linear-gradient(-45deg, 
			transparent 33%,
			rgba(0, 0, 0, .2) 33%, 
			rgba(0,0, 0, .2) 66%,
			transparent 66%),
		linear-gradient(right,
			#005f95,
			#07294d);
	background-size : 60px 30px, 100% 100%, 100% 100%;
	border-radius   : 3px;
}

.add-media-file-div progress[value]::-moz-progress-bar {
	background :
	-moz-linear-gradient(-45deg, 
		transparent 33%,
		rgba(0, 0, 0, .2) 33%, 
		rgba(0,0, 0, .2) 66%,
		transparent 66%),
	-moz-linear-gradient(right,
		#005f95,
		#07294d);
	background :
		linear-gradient(-45deg, 
			transparent 33%,
			rgba(0, 0, 0, .2) 33%, 
			rgba(0,0, 0, .2) 66%,
			transparent 66%),
		linear-gradient(right,
			#005f95,
			#07294d);
	background-size : 60px 30px, 100% 100%, 100% 100%;
	border-radius   : 3px;
}

.add-media-file-div ul {
	list-style-type : none;
	margin          : 0;
	padding         : 0;
}
/*drag*/
legend
{
	font-weight: bold;
	color: #333;
}

#filedrag
{
	display: none;
	font-weight: bold;
	text-align: center;
	padding: 1em 0;
	margin: 1em 0;
	color: #555;
	border: 2px dashed #555;
	border-radius: 7px;
	cursor: default;
}

#filedrag.hover
{
	color: #f00;
	border-color: #f00;
	border-style: solid;
	box-shadow: inset 0 3px 4px #888;
}

img
{
	max-width: 100%;
}

pre
{
	width: 95%;
	height: 8em;
	font-family: monospace;
	font-size: 0.9em;
	padding: 1px 2px;
	margin: 0 0 1em auto;
	border: 1px inset #666;
	background-color: #eee;
	overflow: auto;
}

#messages
{
	padding: 0 10px;
	margin: 1em 0;
	border: 1px solid #999;
}

#progress p
{
	display: block;
	width: 240px;
	padding: 2px 5px;
	margin: 2px 0;
	border: 1px inset #446;
	border-radius: 5px;
	/* background: #eee url("progress.png") 100% 0 repeat-y; */
}

#progress p.success
{
	background: #0c0 none 0 0 no-repeat;
}

#progress p.failed
{
	background: #c00 none 0 0 no-repeat;
}
.add-media-div form {
    border: 2px dashed #555;
    border-radius: 7px;
    color: #555;
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin: 1em 0;
    padding: 50px 0 110px 0;
    text-align: center;
    transition: background 0.3s, color 0.3s;
	    position: relative;
        margin: 0 0 115px 0;
}
.upload_file_div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
}

.upload_file_div input#fileselect {
    height: 100%;
    padding: 155px 5% 0 34%;
	    width: 100%;
		    cursor: pointer;
}
.mediaadd-view-section-right {
    margin: 65px 0 0 0;
}
/*drag END*/
/*add-media page END*/

/*mini site*/
button.confirm-btn {
    text-transform: uppercase;
    color: #FEFEFE;
    font-family: 'CenturyGothicRegular';
    font-size: 13px;
    text-transform: uppercase;
    background: #009EE2;
    border: 1px solid #009EE2;
    padding: 2px 12px;
    border-radius: 30px;
    margin: 0 auto 30px auto;
    display: flex;
}
.home-register-form-div.design-menu-sec.design-logo-sec h5.text-center {
    text-transform: uppercase;
}
/*popup*/
.confirm_modal .modal-content {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #1D1D1B;
	    padding: 0 0 30px 0;
}
.confirm_modal .modal-content button.close {
    position: absolute;
    right: 16px;
    top: 7px;
}
.confirm_modal .modal-content .modal-header {
    border: none;
}
h5.modal-title {
    text-transform: uppercase;
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin: 30px auto 30px auto;
}
.confirm_modal .modal-content input#order-code {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 16.65px;
    font-weight: bold;
    letter-spacing: 0;
    box-sizing: border-box;
    height: 47px;
    width: calc(100% - 40px);
    border: 1px solid #1D1D1B;
    border-radius: 0px;
    margin-bottom: 30px;
    text-align: center;
    margin-left: 20px;
}
.confirm_modal .modal-content .register-btn::before{
	    z-index: 0;
}
.confirm_modal .modal-content .register-btn {
    height: 47px;
    width: 170px;
}
/*popup END*/
.mobile-design-menu-sec {
    margin: 0 auto 40px auto;
}
button.btn_complete_green.minisite-btn_complete_green {
    width: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
}
button.btn_complete_green:focus, button.btn_complete_green.minisite-btn_complete_green:focus, button.btn_sms:focus, button.btn_delete:focus, button.btn_complete:focus {
    outline: none;
	opacity:0.9;
}
/*mini site END*/

/*24-2-21*/
button.btn_complete:focus, button.btn_delete:focus, button.btn_complete_green:focus, button.btn_sms:focus, button.btn_delete.small_btn_delete:focus, button.confirm-btn:focus {
    outline: none;
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
/* pagination */
.pagination {
    width: auto;
    float: right;
}
ul.pagination li {
    width: auto;
    padding: 0px;
    display: flex;
}
ul.pagination li a {
    border: 1px solid #dee2e6;
    padding: .5rem .75rem;
    color: #007bff !important;
    margin-left: -1px;
}
ul.pagination li.active a {
    border: 1px solid #007bff;
    border-right: 2px solid #007bff;
}
ul.pagination li.active a:focus{
    outline: none !important;
}
/*table*/
th.th-name {
    width: 300px;
}
/*24-2-21 END*/
.thankyou-sec-inner h5 {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 26px;
    text-align: center;
    text-transform: uppercase;
	    padding: 0px 50px 140px 50px;
}
.thankyou-sec-inner h2 {
    color: #009EE2;
    font-family: 'CenturyGothicBold';
    font-size: 68px;
    text-transform: uppercase;
    line-height: 75px;
    text-align: center;
    padding: 0 5px;
    margin: 70px 0 100px;
}
.design-menu-sec.design-logo-sec.design-icon-sec h4.m30 {
    margin: 18px 0 30px 0;
}
/*button disable */
.bottomcomplete-button .btn:disabled {
    background: #d4d1ce !important;
}
.uploadsubmit-button-sec {
    position: absolute;
    right: 3px;
    bottom: -90px;
    z-index: 999;
}
.uploadsubmit-button-sec .register-btn {
    width: 160px;
}

/*reset button*/
.bottom-reset-section {
    margin: 30px 0 0 0;
    text-align: left;
}
.bottom-reset-section img {
    width: 29px;
    height: 32px;
}
.bottom-reset-section button {
    border: none;
    background: transparent;
}
.bottom-reset-section button:focus{
	outline:none;
}
button.layout_reset_btn {
    margin: 0 10px 0 0;
}
/*reset button END*/

/*quantity btn*/
.design-logo-grid-outer ul {
    list-style: none;
    margin: 0px;
    width: 100%;
    float: left;
    display: flex;
    border: 1px solid #1D1D1B;
    justify-content: center;
	    margin: 15px 0 10px 0;
}
.design-logo-grid-outer ul li {
    padding: 0 3px;
    text-align: center;
    margin: 0px;
}
.design-logo-grid-outer ul li button {
    border: none;
    background: transparent;
}
.design-logo-grid-outer ul li button:focus{
	outline:none;
}
.spinner-border{
    position: relative;
    left: 25px;
    top: 8px;
}
button.active-tab{
    border: 5px solid #009fe3 !important;
}
/*quantity btn END*/

/*register terms and condition page*/
.home-register-form-div input[type="text"], .home-register-form-div input[type="email"], .home-register-form-div input[type="number"], .home-register-form-div input[type="tel"], .home-register-form-div input[type="password"] {
    color: #1D1D1B;
    font-family: 'CenturyGothicBold';
    font-size: 16.65px;
    font-weight: bold;
    letter-spacing: 0;
    box-sizing: border-box;
    height: 47.36px;
    width: 100%;
    border: 1px solid #1D1D1B;
    border-radius: 0px;
}
.terms-register-form {
    margin-top: 160px;
}
.terms-condition-section {
    width: 100%;
    float: left;
}
.terms-condition-section h5 {
    text-align: center;
    margin: 0px;
    font-size: 14px;
    color: #2c2e35;
	font-family: 'MyriadProBold';
	    line-height: 18px;
}
h5.h5_lighttxt {
    font-family: 'MyriadProRegular';
	margin: 0 0 30px 0;
	    font-weight: 400;
}
.terms-condition-section ul.terms-ul {
    list-style: decimal;
    width: 100%;
	    margin: 0px;
    padding: 0 0 0 20px;
}
.terms-condition-section ul.terms-ul li{
    font-size: 14px;
    font-weight: 800;
    float: left;
    width: 100%;
    font-family: 'CenturyGothicBold';
    line-height: 18px;
    margin-bottom: 15px;
}
.terms-condition-section ul.terms-ul li span{
    font-size: 14px;
    color: #2c2e35;
    font-family: 'CenturyGothicRegular';
    text-align: left;
    line-height: 20px;
    padding: 0 10px 0 5px;
    width: 100%;
    position: relative;
    float: left !important;
    display: contents;
}
.terms-list-outer {
    width: 110%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
	    justify-items: center;
}
.terms-list-outer .terms-list-grid {
    margin: 15px 0 2px 0px;
}
.terms-list-outer .terms-list-grid p {
    font-size: 13px;
    color: #2c2e35;
    font-family: 'CenturyGothicRegular';
    margin: 0px;
    line-height: 18px;
}
.term-list-hypen {
    width: 100%;
    float: left;
    padding: 0 0 0 25px;
}
.term-list-hypen p {
    font-size: 14px;
    color: #2c2e35;
    font-family: 'CenturyGothicRegular';
    margin: 0px;
    line-height: 18px;
}
.register-checkbox-div {
    width: 100%;
}
.checkbox-div.register-checkbox-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 45px 0;
}
.checkbox-div.register-checkbox-div label {
    font-size: 14px;
    color: #2c2e35;
    font-weight: bold;
    font-family: 'MyriadProBold';
    width: auto !important;
    margin: 0px;
}
.form-bottom-text.terms-form-bottom-text {
    margin: 30px 0 0 0;
}
.form-bottom-text.terms-form-bottom-text p {
    font-size: 15px;
    color: #2c2e35;
    opacity: .6;
	    line-height: 20px;
}
/*register terms and condition page END*/
/*Loader*/
.screen_loader{
    position: relative;
    width: 100%;
    float: left;
    display: table;
}
.screen_loader ._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk {
    position: absolute;
    width: 100%;
    /* height: 1366px; */
    left: 0;
    top: 0;
    display: table;
}
p.no-order{
    text-align: center;
}
